<template>
    <div v-if="account">
        <button type="button" ref="element" @click="menu = true">
            <Avatar :firstname="account.firstname" :lastname="account.lastname" size="sm" />
        </button>
        <Menu v-model="menu" :reference="element" direction="bottom-right" class="mt-4">
            <div class="flex gap-4 px-4 py-3 items-center">
                <div class="rounded-full overflow-hidden relative">
                    <button type="button" class="absolute inset-0 bg-black/50 flex opacity-0 hover:opacity-100 duration-300" @click="noFunction">
                        <icon class="m-auto text-white" name="photo_camera" :size="28" />
                    </button>
                    <Avatar :firstname="account.firstname" :lastname="account.lastname" />
                </div>
                <div>
                    <div class="font-medium text-lg grid">
                        <span class="truncate">
                            {{ account.firstname }}
                            {{ account.lastname }}
                        </span>
                    </div>
                    <div class="truncate text-xs text-gray-500">Kd.Nr.: {{ account.customer }}</div>
                </div>
            </div>
            <hr />
            <NuxtLink to="/account">
                <MenuItem icon="badge" @click="menu = false">Mein Account</MenuItem>
            </NuxtLink>
            <NuxtLink to="/account/einstellungen">
                <MenuItem icon="instant_mix" @click="menu = false">Einstellungen</MenuItem>
            </NuxtLink>
            <!-- <MenuItem unavailable icon="help" @click="useNotification().noFunction()">Hilfe</MenuItem> -->
            <!-- <MenuItem unavailable icon="maps_ugc" @click="useNotification().noFunction()">Verbesserung vorschlagen</MenuItem> -->
            <!-- <MenuItem unavailable icon="outgoing_mail" @click="useNotification().noFunction()">Freunde einladen</MenuItem> -->
            <hr />
            <NuxtLink to="/logout">
                <MenuItem icon="logout" @click="menu = false">Ausloggen</MenuItem>
            </NuxtLink>
        </Menu>
    </div>
</template>

<script setup>
const props = defineProps({
    account: {
        type: Object,
        default: null,
    },
});

const menu = ref(false);
const element = ref(null);
</script>
